.filter-results-overlay {
  position: relative;
  transition: all 0.3s ease-in-out;
  opacity: 0.2;

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 10;
  }
}

.filter-dropdown {
  position: relative;
  border-right: 1px solid #efefef;
}

.filter-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  @include media("<md") {
    padding: 0;
  }

  @include media(">md") {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.result-counter {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  @include media(">md") {
    order: 1;
    width: 350px;
    margin-top: 0;
    justify-content: left;
  }
}

.filter-dropdown__button {
  font-size: 16px;
  color: #000;
  border-radius: 27px;
  height: 54px;
  background-color: #fff;
  outline: 0;
  border: 1px solid #868686;
  position: relative;
  cursor: pointer;
  margin-right: 5px;
  padding: 0 45px 0 20px;
  white-space: nowrap;
  background-image: url("../../../images/down_icon.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;

  @include media("<md") {
    margin: 2px 10px 0 0;
  }

  &.open {
    background-image: url("../../../images/up_icon.svg");
  }

  &.active {
    font-weight: 700;
    border-color: #000;
  }

  &:hover {
    border-color: #000;
  }
}

.filter-dropdown__content {
  max-width: 420px;
  width: 100vw;
  display: none;
  top: calc(100% + 10px);
  left: 0;
  position: absolute;
  background-color: #fff;
  border: 1px solid #bfbfbf;
  border-radius: 16px;
  box-shadow: 0 0 22px rgba(0, 0, 0, 0.22);
  z-index: 2;
  padding: 20px;

  &.open {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  header {
    display: grid;
    grid-template-columns: 1fr auto 1fr;

    h5 {
      grid-column-start: 2;
      font-size: 20px;
      font-weight: bold;
      color: #000;
      align-self: center;
    }

    button {
      margin-left: auto;
      border: none;
      width: auto;
      height: auto;
    }
  }

  .scroll {
    height: 400px;
    overflow-y: scroll;
    overflow-x: visible;
    margin: 0 -20px;
    padding: 0 20px;
  }

  button {
    border-radius: 27px;
    width: 280px;
    height: 44px;
    font-size: 16px;
    font-family: "CeraPro";
    font-feature-settings: "ss01" 1;
    color: #000;
    background-color: #fff;
    border: 1px solid #868686;
    cursor: pointer;
    padding: 0;

    &:hover {
      border-color: #000;
    }

    &:disabled {
      opacity: 0.15;
    }

    &.active,
    &.primary {
      color: #fff;
      background-color: #008ca0;
      border-color: #008ca0;
      font-weight: bold;
    }

    &.primary {
      align-self: center;
      margin: 20px 0;
    }
  }

  input[type="text"] {
    border: 1px solid rgb(134, 134, 134);
    border-radius: 27px;
    height: 54px;
    background-repeat: no-repeat;
    background-position: left 20px center;
    padding-left: 50px;
    padding-right: 54px;
    width: 100%;
    outline: 0;

    &.location-search {
      background-image: url("../../../images/marker.svg");

      &.active {
        border-radius: 27px 27px 0 0;
      }

      &.running {
        opacity: 0.3;
      }
    }

    &.categories-search {
      background-image: url("../../../images/search_icon.svg");
      margin: 20px 0;
      padding-right: 54px;
    }
  }

  .input-wrapper {
    position: relative;
  }

  .search-reset {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 20px;
    font-size: 3em;
    width: 54px;
    height: 54px;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../../images/close-2023.svg");
  }

  .autocomplete {
    border: 1px solid #868686;
    position: absolute;
    background-color: white;
    width: 100%;
    border-top: 0;
    box-sizing: border-box;
    border-radius: 0 0 27px 27px;
    overflow: hidden;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      max-height: 250px;
      overflow-y: scroll;

      li {
        padding: 0;

        button {
          height: auto;
          background: none;
          transition: all ease-in-out 0.2s;
          border: 0;
          outline: 0;
          padding: 12px 16px;
          width: 100%;
          font-size: 16px;
          text-align: left;
          cursor: pointer;

          &:hover {
            background-color: #f5f5f5;
            border-radius: 0;
          }
        }
      }
    }
  }

  label {
    cursor: pointer;
    display: flex;
    gap: 20px;
    justify-content: space-between;

    &.active {
      font-weight: bold;
    }
  }

  input[type="checkbox"] {
    &.checkbox {
      appearance: none;
      background-color: #e2e2e2;
      margin: 0;
      margin-left: auto;
      cursor: pointer;

      font: inherit;
      color: currentColor;
      width: 24px;
      height: 24px;
      border: none;
      border-radius: 3px;
      transform: translateY(-0.075em);
      box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.259);

      display: grid;
      place-content: center;

      &::before {
        content: "";
        width: 0.65em;
        height: 0.65em;
        clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
        transform: scale(0);
        transform-origin: bottom left;
        transition: 120ms transform ease-in-out;
        background-color: CanvasText;
      }

      &:checked {
        background: #008ca0;

        &::before {
          transform: scale(1);
          background: #fff;
        }
      }

      &:focus {
        outline: max(2px, 0.15em) solid #008ca0;
        outline-offset: max(2px, 0.15em);
      }

      &:disabled {
        opacity: 0.15;
        cursor: not-allowed;
      }
    }

    &.switch {
      --switch-gray: #e2e2e2;
      --switch-blue: #008ca0;
      --switch-white: #ffffff;
      --switch-black: transparent;
      --switch-size: 1em;
      --switch-border-thickness: 0.1em;
      --switch-color-border: var(--switch-black);
      --switch-color-background: var(--switch-gray);
      --switch-color-ball: var(--switch-white);
      --switch-border-radius-inner: calc(var(--switch-size) / 2);
      --switch-border-radius: calc(
        var(--switch-border-radius-inner) + var(--switch-border-thickness)
      );
      appearance: none;
      position: relative;
      box-sizing: content-box;
      display: inline-block;
      vertical-align: middle;
      width: calc(var(--switch-size) * 2);
      height: var(--switch-size);
      border-radius: var(--switch-border-radius);
      font-size: 1.5rem;
      background: var(--switch-color-background);
      transition: background-color 0.2s;
      cursor: pointer;
      box-shadow: inset 0 1px 4px 0 #00000042;
      padding: 2px 0;

      &:before {
        content: "";
        position: absolute;
        left: calc(var(--switch-size) * 0.1);
        width: var(--switch-size);
        height: var(--switch-size);
        border-radius: var(--switch-border-radius-inner);
        background-color: var(--switch-color-ball);
        transition:
          background-color 0.2s,
          left 0.2s;
      }

      &:checked {
        --switch-color-background: var(--switch-blue);
      }

      &:checked:before {
        --switch-color-ball: var(--switch-white);
        box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.26);
        left: calc(var(--switch-size) * 0.9);
        background-image: url("../../../images/tick_toggle.svg");
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }

  .message {
    text-align: center;
  }

  a {
    color: #008ca0;
    text-decoration: underline;
    align-self: center;
  }

  h6 {
    align-self: start;
    font-size: 16px;
    font-weight: bold;
    color: #000;
    margin-top: 20px;
  }

  .filter-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 5px;
    flex-direction: column;

    button {
      max-width: 180px;
    }
  }

  .filter-time {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;

    & > * {
      margin: 5px;
    }

    input {
      width: 100%;
      border-radius: 20px;
      border: 1px solid #cecece;
      background-color: #ffffff;
      padding: 5px 20px;
      text-align: center;
    }
  }

  &.location .filter-row {
    flex-direction: row;
    gap: 5px;
  }

  @include media("<md") {
    padding: 10px;
    width: unset !important;

    &.location {
      left: -50%;
    }
  }

  &.letter-container {
    .filter-row {
      flex-direction: column;
    }

    h6 {
      background: #eee;
      color: #979797;
      margin: 10px -20px;
      padding: 5px 20px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    label {
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.map-toggle-mobile-container {
  display: none;
  position: sticky;
  width: 100%;
  bottom: 24px;
  z-index: 100;
  margin-bottom: 40px;
  height: 55px;

  @include media("<md") {
    display: flex;
  }
}

.filter-map-toggle {
  background: #fff;
  border: 1px solid #979797;
  font-size: 16px;
  padding: 13px 20px 13px 57px;
  border-radius: 27px;
  line-height: 27px;
  background-image: url("../../../images/map-icon.svg");
  background-repeat: no-repeat;
  background-position: 20px center;
  cursor: pointer;
  margin-left: auto;
  transition: all ease-in-out 0.2s;
  height: 100%;
  display: flex;
  gap: 20px;

  @include media("<md") {
    .map-toggle-mobile-container & {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      box-shadow: 0 4px 22px #00000052;
      width: 300px;
    }
  }

  &:hover {
    border-color: #000;
  }

  > input[type="checkbox"] {
    height: 0;
    width: 0;
    visibility: hidden;

    &:checked + label {
      background: #008ca0;
      box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.26);

      &:after {
        left: 21px;
        background-size: 50%;
      }
    }
  }

  > label {
    float: right;
    cursor: pointer;
    text-indent: -9999px;
    background: #e2e2e2;
    box-shadow: inset 0 1px 4px 0 rgba(0, 0, 0, 0.26);
    border-radius: 16px;
    height: 27px;
    width: 45px;
    display: block;
    position: relative;
    transition: all ease-in-out 0.2s;

    &:after {
      display: block;
      content: "";
      height: 21px;
      width: 21px;
      position: absolute;
      left: 3px;
      top: 3px;
      background-color: #fff;
      border-radius: 50%;
      transition: all ease-in-out 0.1s;
      background-image: url("../../../images/tick_toggle.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: 0%;
    }
  }
}

.filter-reset {
  margin: 10px 0 10px auto;
  cursor: pointer;
  padding: 0;
  background: none;
  text-align: right;
  outline: 0;
  width: 114px;
  transition: all 0.2s ease-in-out;

  &:disabled {
    opacity: 0.2;
    cursor: default;
  }

  // FIXME
  /* stylelint-disable-next-line no-descending-specificity */
  svg {
    vertical-align: text-top;
    margin-right: 5px;
  }

  // @include media('<lg') {
  //   width: 17%;
  // }

  @include media("<md") {
    margin-right: 10px;
    width: auto;
  }
}

.product-list-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  @include media("<md") {
    flex-direction: column;
  }

  h3 {
    font-size: 20px;
    margin: 0;

    @include media("<md") {
      font-size: 16px;
      margin: 7px 0 10px;
    }
  }
}

.favorites-button {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 3px;
  outline: 0;
  position: relative;
  padding: 5px 20px 5px 35px;
  cursor: pointer;

  @include media("<md") {
    font-size: 12px;
  }

  // FIXME
  /* stylelint-disable-next-line no-descending-specificity */
  svg {
    width: 15px;
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);

    .heart {
      fill: transparent;
      transition: all 0.2s ease-in-out;
      stroke: #8e8e8e;
    }
  }

  &:hover {
    svg {
      .heart {
        stroke: #fd5656;
      }
    }
  }

  &.active {
    // FIXME
    /* stylelint-disable-next-line no-descending-specificity */
    .heart {
      fill: #fd5656;
      stroke: #fd5656;
    }
  }
}

.filter {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .filter-bubbles-container {
    display: flex;
    width: 100%;
  }

  @include media("<md") {
    box-shadow: none;
    border-bottom: 1px solid #efefef;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;

    .filter-bubbles-container {
      order: 3;
      margin: 0 10px;
    }

    .sort-by_button {
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .dropdown-fixed,
    .sort-by {
      width: 43%;
      margin: 0 10px;

      button {
        width: 100%;
      }
    }
  }

  .checkbox {
    input {
      position: absolute;
      left: -999999px;

      & + label {
        background-color: #fff;
        cursor: pointer;
        font-size: 16px;
        padding: 8px 10px 8px 40px;
        position: relative;
        transition: all 0.2s ease-in-out;
        display: block;

        &:hover {
          background-color: #f4f4f4;
        }

        &:before {
          content: "";
          display: block;
          height: 24px;
          width: 24px;
          border-radius: 3px;
          top: 10px;
          left: 0;
          position: absolute;
          border-radius: 3px;
          box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.259252) inset;
          background-color: rgba(226, 226, 226, 1);
        }
      }

      &:disabled {
        & + label {
          cursor: default;
          opacity: 0.3;

          &:hover {
            background-color: #fff;
          }
        }
      }

      &:checked {
        // FIXME
        /* stylelint-disable-next-line no-descending-specificity */
        & + label {
          font-weight: 700;

          &:before {
            background-color: #008ca0;
          }

          &:after {
            content: "";
            display: block;
            width: 7px;
            height: 13px;
            background-color: #008ca0;
            border: solid white;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
            position: absolute;
            left: 9px;
            top: 15px;
          }
        }
      }

      &:focus {
        // FIXME
        /* stylelint-disable-next-line no-descending-specificity */
        & + label {
          background-color: #f4f4f4;
        }
      }
    }

    i {
      width: 28px;
      text-align: center;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
      line-height: 1em;

      svg {
        height: 20px;
      }
    }
  }
}

.filter-btn {
  border-radius: 27px;
  width: 280px;
  height: 54px;
  font-weight: 700;
  font-size: 16px;
  font-family: "CeraPro";
  font-feature-settings: "ss01" 1;
  color: #fff;
  background-color: #008ca0;
}

.filter-bubbles {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 24px;

  @include media("<lg") {
    margin-top: 24px;
  }
}

.filter-bubble-label {
  margin: 0 5px 5px 0;
  background-color: #008ca0;
  font-size: 14px;
  color: #fff;
  border-radius: 15px;
  padding: 5px 15px;
  line-height: 14px;
  display: flex;
  align-items: center;

  button {
    font-size: 50px;
    cursor: pointer;
    background-repeat: no-repeat;
    background-size: 9px;
    background-color: transparent;
    width: 9px;
    height: 9px;
    display: inline-block;
    margin-left: 10px;
  }
}

.sort-by {
  position: relative;
  width: 300px;
  z-index: 1999;
  @include media(">md") {
    order: 2;
    margin-left: auto;
  }

  .sort-by_button {
    font-size: 14px;
    color: #000;
    padding: 1px 42px 1px 20px;
    border-radius: 17px;
    background-color: #fff;
    outline: 0;
    border: 1px solid #868686;
    position: relative;
    cursor: pointer;
    background-image: url("../../../images/down_icon.svg");
    background-repeat: no-repeat;
    background-position: right 10px center;

    @include media("<md") {
      background-color: #fff;
      background-position: left 30px center;
      background-repeat: no-repeat;
      border: 1px solid #868686;
      border-radius: 27px;
      color: #000;
      cursor: pointer;
      font-size: 16px;
      height: 54px;
      margin-right: 5px;
      outline: 0;
      padding: 0 30px 0 53px;
      position: relative;
      white-space: nowrap;
      background-image: url("../../../images/sort.svg");

      span {
        display: none;
      }
    }

    @include media(">md") {
      &.open {
        background-image: url("../../../images/up_icon.svg");
      }
    }

    &.active {
      font-weight: 700;
      border-color: #000;
    }

    &:hover {
      border-color: #000;
    }
  }

  .popup {
    background-color: #fff;
    border: 1px solid #bfbfbf;
    border-radius: 16px;
    box-shadow: 0 0 22px rgba(0, 0, 0, 0.22);
  }

  > button {
    width: 100%;
    text-align: left;
  }

  .sort-by_header {
    padding: 16px 24px;
    color: #949494;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: 0.108em;
    font-family: Arial;
    display: block;
  }

  .popup {
    position: absolute;
    top: calc(100% + 10px);
    width: 100%;
    height: auto;
    overflow: hidden;

    @include media("<md") {
      position: fixed;
      bottom: 0;
      z-index: 2000;
      height: 500px;
      top: unset;
      width: 100%;
      left: 0;
    }

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        button {
          background: none;
          outline: 0;
          border: 0;
          font-size: 16px;
          padding: 9px 24px;
          display: block;
          width: 100%;
          transition: all ease-in-out 0.2s;
          text-align: left;
          cursor: pointer;

          &.active {
            font-weight: 700;
          }

          &:hover {
            background-color: #f6f6f6; // TODO: this is reponsible for the product detail dropdown ☇
          }
        }
      }
    }
  }
}
