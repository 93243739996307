.slider-wrapper {
  padding-top: 24px;

  .row {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;

    .action-text-green {
      font-weight: 700;
      font-size: 24px;
      margin-right: 7px;
      color: var(--text-text-brand, #34b78f);
    }

    .action-text {
      font-weight: 700;
      font-size: 24px;
      color: #000;
    }

    button.action-button {
      height: 48px;
      padding: 12px 24px;
      background: white;
      border-radius: 360px;
      overflow: hidden;
      border: 1px #122020 solid;
      justify-content: center;
      align-items: center;
      gap: 8px;
      display: inline-flex;
      cursor: pointer;

      span.action-button-text {
        color: #122020;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        word-wrap: break-word;
      }
    }
  }
}

.badge-slider {
  width: 100%;

  &.slick-slider {
    padding: 0px 20px;
    margin: 0px 0px;
  }

  div {
    padding: 0px 0px;
    margin: 0px 0px;
    display: flex;

    &.card {
      margin: var(--spacing-spacing-md, 40px) var(--spacing-spacing-md, 20px);
      padding: 32px 24px;
      height: 300px;
      width: 100%;
      border-radius: var(--radius-radius-rounded, 8px);
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      position: relative;
      align-items: center;

      &.obtained {
        filter: grayscale(0);
        opacity: 1;
      }

      &:hover {
        box-shadow: 0 0 40px rgba(0, 0, 0, 0.25);
        transition: box-shadow 0.1s ease-in-out;
      }

      span {
        &.title {
          height: 32px;
          text-align: center;
          color: black;
          font-size: 20px;
          font-weight: 700;
          letter-spacing: 0.6px;
          word-wrap: break-word;
        }
        &.details {
          color: #122020;
          font-size: 16px;
          font-weight: 400;
          text-decoration: underline;
          line-height: 24px;
          word-wrap: break-word;
          cursor: pointer;
        }
      }

      p.obtained {
        display: inline-flex;
        justify-content: center;
        align-items: center;
        margin-top: 24px;
        margin-bottom: 14px;
        gap: 8px;
        color: #34b78f;
        font-size: 14px;
        font-family: "CeraPro", sans-serif;
        font-feature-settings: "ss01" 1;
        font-weight: 700;
        line-height: 20px;
        word-wrap: break-word;
      }

      img {
        width: 100px;
        height: 100px;
        filter: grayscale(0);
        opacity: 1;
      }

      a {
        display: inline-flex;
        padding: 12px 20px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 360px;
        background: #34b78f;
        margin-top: 12px;
        cursor: pointer;
        overflow: hidden;
        color: white;
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
        word-wrap: break-word;
      }
    }

    .card:not(.obtained) img {
      filter: grayscale(1);
      opacity: 0.4;
    }
  }

  .slick-prev,
  .slick-next {
    z-index: 10;
    height: 100%;
    width: 50px;
  }

  .slick-prev {
    &::before {
      display: none;
    }

    &::after {
      display: inline-block;
      background-size: cover;
      background-repeat: none;
      height: 28px;
      width: 15px;
      background-image: url("../../../static/img/badge-slider/badge-slider-arrow-left.svg") !important;
      content: "";
    }
  }

  .slick-next {
    &::before {
      display: none;
    }

    &::after {
      display: inline-block;
      background-size: cover;
      background-repeat: none;
      height: 28px;
      width: 15px;
      background-image: url("../../../static/img/badge-slider/badge-slider-arrow-right.svg") !important;
      content: "";
    }
  }

  span {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.48px;
    margin-top: 12px;
    text-align: center;
  }
}

@media (max-width: 768px) {
  .badge-slider {
    &.slick-slider {
      padding: 0px 0px;
      margin: 0px 0px;
    }

    div {
      &.card {
        padding: 32px 16px;

        span {
          &.title {
            min-height: 20px;
            font-size: 14px;
            text-align: center;
          }
          &.details {
            font-size: 14px;
            line-height: 14px;
            word-wrap: break-word;
            cursor: pointer;
          }
        }
        a {
          width: auto;
          margin-top: 16px;
          font-size: 12px;
          line-height: 12px;
          text-align: center;
        }
      }
    }

    .slick-prev,
    .slick-next {
      width: 10px;
    }

    .slick-prev {
      left: 0px;

      &::after {
        display: inline-block;
        background-size: cover;
        background-repeat: none;
        height: 18px;
        width: 10px;
        background-image: url("../../../static/img/badge-slider/badge-slider-arrow-left.svg") !important;
        content: "";
      }
    }

    .slick-next {
      right: 0px;

      &::after {
        display: inline-block;
        background-size: cover;
        background-repeat: none;
        height: 18px;
        width: 10px;
        background-image: url("../../../static/img/badge-slider/badge-slider-arrow-right.svg") !important;
        content: "";
      }
    }
  }
}
